import axios from 'axios'
import { Message,} from "element-ui";
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
})

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    const code = res.code || 200 || 0;
    // console.log("service.interceptors.response.data.code",code)
    // 获取错误信息
    const message =res.msg || res.message
    if (code === 403) {
      Message.warning("登录超时，请重新登录！");
      return
    }else if (code === 401) {
      Message.warning("未登陆，请重新登录！");
      return
    }else if (code === 505) {
      Message.warning("未登陆，请重新登录！");
      return
    } else if (code === 500) {
      Message({
        message: message,
        type: 'error'
      })
      return Promise.reject(new Error(message))
    }
    // else if (code !== 0 && code !== "0") {
    //     this.$message.warning(message);
    //     return Promise.reject('error')
    // }
    else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
