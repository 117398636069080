import request from '../utils/request'

//直梯-实时监控、对讲 - 可切换清晰度
export function monitor(params,headers) {
    return request({
        url: '/open/api/video/elevator/monitor',
        headers:{
            Authorization:headers.Authorization,
            token:headers.token
        },
        method: 'get',
        params:params
    })
}

// 直梯-录像回放
export function replayVideo(params,headers) {
    return request({
        url: '/open/api/video/elevator/replay',
        headers:{
            Authorization:headers.Authorization,
            token:headers.token
        },
        method: 'get',
        params:params
    })
}